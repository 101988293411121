import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ElfsightWidget } from "react-elfsight-widget"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/sections/Services"
import CalButton from "../components/Calendar"

const UhlirskeJanovice = ({ data }) => {
  const { frontmatter } = data.allMarkdownRemark.edges[0].node

  let contact_instagram = "https://www.instagram.com/tommy_barber_uj/"
  let contact_tel = "+420776157160"

  return (
    <Layout>
      <Seo title="Nestříháme, ale udáváme styl v Uhlířských Janovicích | Tommy Barber Uhlířské Janovice" />
      <div className="animate-triangle">
        <svg
          width="405"
          height="404"
          viewBox="0 0 405 404"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.7" filter="url(#filter0_f_13_235)">
            <path
              d="M366.987 365.53L42.8597 281.796L277.439 42.9601L366.987 365.53Z"
              stroke="url(#paint0_linear_13_235)"
              strokeWidth="18"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_13_235"
              x="0.515625"
              y="0.532288"
              width="404.313"
              height="402.61"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="12.5"
                result="effect1_foregroundBlur_13_235"
              />
            </filter>
            <linearGradient
              id="paint0_linear_13_235"
              x1="25.5157"
              y1="286.611"
              x2="432.675"
              y2="173.58"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#696969" />
              <stop offset="1" stopColor="#D7D7D7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="right-0 left-auto hidden md:block top-1/2 animate-triangle">
        <svg
          width="210"
          height="208"
          viewBox="0 0 210 208"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.7" filter="url(#filter0_f_34_424)">
            <path
              d="M175.572 33.5319L39.1503 68.7744L137.882 169.298L175.572 33.5319Z"
              stroke="url(#paint0_linear_34_424)"
              strokeWidth="19"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_34_424"
              x="0.842773"
              y="0.218201"
              width="208.284"
              height="207.475"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10"
                result="effect1_foregroundBlur_34_424"
              />
            </filter>
            <linearGradient
              id="paint0_linear_34_424"
              x1="20.8427"
              y1="63.692"
              x2="214.227"
              y2="117.377"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#696969" />
              <stop offset="1" stopColor="#D7D7D7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <section className="container mt-24 mb-10">
       <div class="">

        <div className="mb-10">
          <div className="max-w-3xl">
            <h1 className="mb-8 text-base font-semibold tracking-widest uppercase opacity-100 text-light font-oswald">
              Tommy Barber Uhlířské Janovice
            </h1>
            <div className="inline-flex flex-col items-start justify-start space-y-6">
              <h2 className="text-5xl font-semibold leading-tight uppercase lg:leading-snug lg:text-6xl text-light text-balance">
                Nestříháme, ale udáváme styl v Uhlířských Janovicích
              </h2>
              <p className="text-base leading-relaxed font-montserrat text-light">
                Doba desetiminutového stříhání bez kvalitní úpravy a servisu je
                dávno pryč. Nám záleží na Vaší spokojenosti a z obyčejného
                stříhání Vám uděláme zážitek.
              </p>
            </div>
          </div>
        </div>

            </div>
      </section>


      <section id="zakaznici" className="pb-20 mt-10">
        <div className="mx-auto container-fluid max-w-screen-2xl">
          <div className="grid grid-cols-2 lg:grid-cols-4">
            <div className="p-2">
              <StaticImage
                src="../images/prostory/1.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80  "
              />
            </div>
            <div className="p-2">
              <StaticImage
                src="../images/prostory/2.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80 "
              />
            </div>
            <div className="p-2">
              <StaticImage
                src="../images/uj-hero.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80 "
              />
            </div>
            <div className="p-2">
              <StaticImage
                src="../images/prostory/4.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80 "
              />
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            <div className="p-2">
              <StaticImage
                src="../images/prostory/5.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80 xl:h-96  "
              />
            </div>
            <div className="p-2">
              <StaticImage
                src="../images/prostory/6.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full h-40 duration-200 ease-in-out md:h-80 xl:h-96"
              />
            </div>
            <div className="col-span-2 p-2 md:col-span-1">
              <StaticImage
                src="../images/jana-tommybarber.jpeg"
                alt="Tommy Barber Uhlířské Janovice"
                className="object-cover w-full duration-200 ease-in-out md:h-80 xl:h-96"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mx-auto md:pb-8" id="nase-sluzby">
      <div className="container">
        <h2 className="pb-8 text-center ">Naše služby</h2>
        <p className="mx-auto text-center md:max-w-lg ">
          Zaměřujeme se na perfektní střih a úpravu vousů. U nás odejdete
          nejenom se zážitkem, ale i s perfektním účesem.
        </p>
        <div className="grid gap-8 mx-auto mt-8 md:grid-cols-2">
          <div className="inline-flex flex-col p-8 space-y-10 border-2 bg-dark border-light">
            <p className="text-3xl leading-9 text-center text-white uppercase opacity-100 font-oswald">
              Střihy
            </p>
            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Pánský střih
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  390 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                45 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
              Mytí vlasů, střih, zaholení kontur, úprava obočí, opalování uší, opětovné mytí vlasů, masáž hlavy, vysušení, styling a na závěr kolínská.
              </p>
            </div>

            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  dětský střih (do 4 let)
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  290 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                30 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
              Konzultace, střih, zaholení kontur, mytí vlasů, vysušení, styling.
              </p>
            </div>
            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Střih dlouhých vlasů
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  490 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                60 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Konzultace, střih strojkem jedna velikost, zaholení kontur,
                úprava obočí, opalování uší, mytí vlasů, masáž hlavy, vysušení,
                styling a na závěr kolínská.
              </p>
            </div>
          </div>

          <div className="inline-flex flex-col p-8 space-y-10 border-2 bg-dark border-light ">
            <p className="text-3xl leading-9 text-center text-white uppercase opacity-100 font-oswald">
              vousy
            </p>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Klasické holení břitvou "HOT TOWEL"
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  460 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                45 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Konzultace, napaření vousů horkým ručníkem, holení břitvou,
                zábal obličeje horkým ručníkem a na závěr kolínská.
              </p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Úprava vousů “pouze strojkem“
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  390 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                15 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Úprava vousů strojkem, úprava obočí a opalování uší a na závěr
                kolínská.
              </p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  BARVENÍ VOUSŮ
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  280 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                Čas dle typu vousů
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat"></p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Depilace nosních a ušních chloupků
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                120 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                10 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat"></p>
            </div>
          </div>
        </div>
        <div className="inline-flex flex-col p-8 mt-8 space-y-10 border-2 bg-dark border-primary ">
          <p className="text-3xl leading-9 text-center text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
            All in one
          </p>
          <div>
            <div className="inline-flex items-center justify-between w-full ">
              <p className="mr-2 text-2xl leading-normal text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                Kompletka
              </p>
              <p className="text-xl leading-normal text-transparent uppercase opacity-100 whitespace-nowrap font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
              690 Kč
              </p>
            </div>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              (střih vlasů+střih vousů s HOT TOWEL)
            </p>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              60 minut
            </p>
            <p className="text-sm normal-case opacity-80 font-montserrat">
            Konzultace, napaření vousů horkým ručníkem, holení břitvou, zábal obličeje horkým ručníkem a na závěr kolínská.
            </p>
          </div>
          <div>
            <div className="inline-flex items-center justify-between w-full ">
              <p className="mr-2 text-2xl leading-normal text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                Kompletka + depilace
              </p>
              <p className="text-xl leading-normal text-transparent uppercase opacity-100 whitespace-nowrap font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                760 Kč
              </p>
            </div>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              (STŘIH VLASŮ + STŘIH VOUSŮ S HOT TOWEL + DEPILACE)
            </p>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              75 minut
            </p>
            <p className="text-sm normal-case opacity-80 font-montserrat">
              Konzultace, střih, zaholení kontur, úprava obočí, úprava vousů,
              napaření horkým ručníkem, holení břitvou, zábal obličeje horkým
              ručníkem, opalování uší, depilace nosních a ušních chloupků, mytí
              vlasů, vysušení, styling a na závěr kolínská.
            </p>
          </div>
        </div>
      </div>
    </section>
      <section id="rezervace">
        <div className="container my-14">
          <div className="bg-[#101010] p-6 md:p-12 text-center">
            <div class="">

            <h2 className="text-4xl leading-tight lg:leading-tight lg:text-5xl text- max-w-lg mx-auto">
              NEČEKEJ NA NOVÝ FRESH CUT,{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
                OBJEDNEJ SE HNED!
              </span>
            </h2>
            <div className="mt-12 flex flex-col md:flex-row gap-14 md:gap-24 items-center justify-center">
            <div>

            <CalButton name="online" calUrl="https://cal.com/tommybarber-mziko" />
            </div>

            <div className="text-left">
              <div className="flex items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="56"
                    height="56"
                    rx="4"
                    fill="url(#paint0_linear_34_348)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.5888 20.3266C22.8526 20.1153 23.1693 20 23.4907 20C23.8121 20 24.1287 20.1153 24.3926 20.3266L24.431 20.3458L24.4502 20.365L26.9065 22.9009L26.964 22.9585L26.9448 22.9777C27.4893 23.4844 27.4797 24.3441 26.964 24.8604L25.7359 26.0899C25.9254 26.5222 26.4387 27.6268 27.4438 28.5874C28.456 29.5552 29.5139 30.1027 29.9576 30.3164L31.1858 29.0869C31.6943 28.5778 32.5962 28.5778 33.1047 29.0869L33.1239 29.1253L35.6186 31.6228C36.1271 32.1319 36.1271 33.0156 35.6186 33.5247L33.6804 35.465C33.1047 35.9597 32.2964 36.1374 31.5504 35.8876H31.5312C30.0896 35.3233 27.1751 34.0434 24.5653 31.4306C21.9651 28.8275 20.6338 25.9483 20.1133 24.457C20.1121 24.4546 20.1127 24.451 20.1133 24.4474C20.1139 24.4438 20.1145 24.4402 20.1133 24.4378C19.8566 23.7294 20.0389 22.9105 20.6314 22.363L20.6122 22.3438L22.5312 20.365L22.5504 20.3458L22.5888 20.3266ZM23.6247 21.2872C23.5816 21.2511 23.536 21.2295 23.4904 21.2295C23.4448 21.2295 23.3993 21.2511 23.3561 21.2872L21.4947 23.1891L21.4563 23.2275C21.238 23.4148 21.1709 23.7846 21.2644 24.0344C21.7298 25.3743 22.9987 28.1335 25.4286 30.5662C27.8393 32.9796 30.5427 34.1682 31.9339 34.7158C32.2913 34.8358 32.5912 34.7854 32.8742 34.5429L34.7548 32.6602C34.8076 32.6073 34.834 32.5905 34.834 32.5737C34.834 32.5569 34.8076 32.5401 34.7548 32.4873L32.2409 29.9706C32.1882 29.9178 32.1666 29.8914 32.145 29.8914C32.1234 29.8914 32.1018 29.9178 32.049 29.9706L30.226 31.7957L29.8422 31.6228C29.8422 31.6228 28.1607 30.9096 26.7335 29.6056L26.5992 29.4903C25.1168 28.0759 24.4115 26.186 24.4115 26.186L24.2772 25.8018L24.565 25.5328L26.1002 23.9959C26.1635 23.9326 26.1434 23.8762 26.139 23.8637C26.1382 23.8616 26.1379 23.8607 26.1386 23.8615L26.081 23.8038L23.6247 21.2872Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_34_348"
                      x1="28"
                      y1="0"
                      x2="28"
                      y2="56"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#696969" />
              <stop offset="1" stopColor="#D7D7D7" />
                    </linearGradient>
                  </defs>
                </svg>

                <div className="ml-4">
                  <a href="tel:+420777645648" className="text-xl">
                    +420 777 645 648
                  </a>
                  <p className="text-sm">Volejte Po - Pá 9:00 - 18:00</p>
                </div>
              </div>
            </div>
            </div>
            </div>
           
           
          </div>
        </div>
      </section>
      <section id="o-nas" className="relative">
        <div className="container py-32 md:py-40">
          <div className="md:w-8/12 lg:w-1/2">
            <h2 className="pb-8">O nás</h2>
            <p className="mb-4">
              Chceme abyste se u nás cítili jako v bavlnce a snažíme se pro to
              dělat maximum. Doba desetiminutového stříhání bez kvalitní úpravy
              a servisu je dávno pryč. Nám záleží na Vaší spokojenosti a z
              obyčejného stříhání Vám uděláme zážitek.
            </p>
            <p className="mb-8">
              U nás je samozřejmostí stoprocentní kvalita a péče. Používáme
              české produkty nejvyšší kvality. Díky zkušenostem z pražských
              Barber shopů Vám můžeme nabídnout ty nejlepší trendy účesy!
            </p>
            <p className="text-xl font-semibold tracking-widest opacity-100 text-light font-oswald">
              The Barber shop since 2024
            </p>
          </div>
        </div>
        <div className="absolute top-0 right-0 left-auto object-contain -z-[1]">
          <StaticImage
            src="../images/onas-bg.jpg"
            alt="Tommy Barber Uhlířské Janovice"
          />
        </div>
      </section>

      <section id="kde-nas-najdete" className="py-20">
        <div className="container">
          <div className="flex flex-col lg:items-center lg:flex-row">
            <div className="lg:w-2/5">
              <h2>Kde nás najdete</h2>
              <div className="my-10 text-lg">
                <h3 className="mb-2 text-2xl">Adresa</h3>
                <p className="mb-4 font-oswald">
                  Kolínská 184, 285 04 Uhlířské Janovice 
                </p>
                <a
                  href="https://maps.app.goo.gl/FGDSMbg3U3hLWuG48"
                  className="underline uppercase"
                  target="blank"
                  rel="nofollow"
                >
                  Navigovat
                </a>
              </div>
              <div className="my-10">
                <h3 className="mb-2 text-2xl">Otevírací doba</h3>
                <table className="text-lg text-light opacity-80">
                  <tbody>
                    <tr>
                      <td className="pr-8 align-top">PO - PÁ</td>
                      <td>9.00 - 12:00,  <br />12:45 - 18.00</td>
                    </tr>
                    <tr>
                      <td>SO</td>
                      <td>dle objednání</td>
                    </tr>
                    <tr>
                      <td>NE</td>
                      <td>zavřeno</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="lg:w-3/5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.901161523347!2d15.063630977750988!3d49.88188267148915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c694eb419d745%3A0x9e2fd28c753e11ab!2sTommy%20Barber!5e0!3m2!1scs!2scz!4v1717949607038!5m2!1scs!2scz" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="flex flex-col justify-between md:items-end md:flex-row">
            <h2 className="max-w-md mb-10 text-2xl lg:mb-0">
              AKTUÁLNĚ Z NAŠEHO BARBERU
            </h2>
            <div className="">
              <p className="mb-2 font-semibold md:mb-0">
                Sleduj nás na instagramu{" "}
                <a href={contact_instagram} rel="nofollow">
                  @tommy_barber_uj
                </a>
              </p>
            </div>
          </div>
          <div className="my-8 overflow-hidden">
            <ElfsightWidget widgetID="8928ad06-c337-4baa-9f82-253bc3de4235" />
          </div>
        </div>
      </section>
      <aside className="fixed bottom-10 right-5">
        <a
          title="Objednat se"
          href="#rezervace"
          className="flex items-center px-6 py-4 text-base tracking-widest text-dark no-underline uppercase lg:hidden bg-gradient-to-r from-primary to-secondary hover:text-white font-oswald border-primary hover:bg-primary"
        >
          <span>Objednat se</span>
        </a>
      </aside>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  return (
    <>
      <Seo title="Nestříháme, udáváme styl" />
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_instagram
              contact_tel
            }
          }
        }
      }
    }
  }
`
export default UhlirskeJanovice
